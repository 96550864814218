@font-face {
  font-family: 'Oxanium-Bold';
  src: local('Oxanium-Bold'), url(./fonts/Oxanium-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Oxanium-Regular';
  src: local('Oxanium-Regular'), url(./fonts/Oxanium-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.header {
  background-color: #f1f1f1;
  padding: 20px;
}

.header h1 {
  text-align: center;
  margin-bottom: 0;
  font-size: 20px;
}

.header p {
  text-align: center;
}

.header .logo {
  text-align: center;
}

.header .logo img {
  cursor: pointer;
  width: 100px;
}

.menu {
  background-color: #000;
  text-align: center;
  padding: 15px 0;
}

.menu .menuItem {
  display: inline-block;
  margin: 0 30px;
}

.menu .menuItem a {
  color: #fff;
  text-decoration: none;
}

.page {
  padding: 30px;
}

.page h2 {
  text-align: center;
}

.page p {
  text-align: center;
}

.inputs .input {
  text-align: center;
}

.inputs .input input {
  height: 30px;
  width: 300px;
  margin: 5px 0;
}

.inputs .button {
  text-align: center;
}

.inputs .button button {
  background-color: #555;
  border: none;
  padding: 15px 30px;
  color: #fff;
  width: 308px;
  margin: 10px 0;
}

.error {
  background-color: red;
  width: 280px;
  margin: 5px 0;
  margin: 0 auto;
  padding: 15px;
  color: #fff;
}

.null_headerBG {
  background-color: #54b021;
}

.dev_headerBG {
  background-color: #54b021;
}

.ierpro_headerBG {
  background-color: #54b021;
}

.kornferry_headerBG {
  background-color: #00634f;
}

.hoganlovells_headerBG {
  background-color: #bed600;
}

.greatforest_headerBG {
  background-color: #FFFFFF;
}

.procurri_headerBG {
  background-color: #00B5AD;
}

.aoc_headerBG {
  background-color: #002e5d;
}